<template>
  <div class="user-management">
    <button class="user-management__button" @click="changeView('Accounts')">
      <i class="ion ion-chevron-back-outline"></i>
      {{ $t("userManagement.back") }}
    </button>
    <b-overlay :show="loading" rounded="sm" class="loading-overlay">
      <button class="user-management__button--new-user" @click="createNewUserModal()">
        <span>{{ $t("userManagement.newUser") }}</span>
      </button>
      <div class="user-management--mobile" v-if="screenWidth && screenWidth <= 800">
        <RowCollapse @removeUser="deletedUser" @disableLoading="disableLoading" :data="users">
          <template #collapsable-detail="{ item }">
            <UserDetails :users="users" :userInfo="item" @deletedUser="deletedUser" />
          </template>
        </RowCollapse>
        <div v-if="!users || users.length === 0" class="user-management--mobile__notFound">
          <UsersNotFound />
          <p>{{ $t("userManagement.noUsersFound") }}</p>
        </div>
      </div>
      <div class="user-management__content" id="user-content" v-else-if="screenWidth > 800">
        <div
          class="user-management__left"
          :class="userInfo ? 'padding-left' : ''"
          v-if="users && users.length"
        >
          <UserList
            v-if="roles"
            :roles="roles"
            :users="users"
            @selectedUser="handleSelectedUser"
            @activeRow="handleActiveRow"
            @disableLoading="disableLoading"
            :userInfo="userInfo"
            ref="userList"
          />
        </div>
        <div class="user-management__right" v-if="users && users.length">
          <UserDetails :users="users" :userInfo="userInfo" @deletedUser="deletedUser" />
        </div>
        <div v-if="(!users || users.length === 0) && !loading" class="noUsers-found">
          <UsersNotFound />
          <p>{{ $t("userManagement.noUsersFound") }}</p>
        </div>
      </div>
      <template #overlay>
        <div class="loading" v-show="loading">
          <b-icon icon="truck" font-scale="4" animation="cylon" class="loading__text"></b-icon>
          <p class="loading__text">{{ $t("loading") }}...</p>
        </div>
      </template>
    </b-overlay>
    <b-modal
      centered
      v-model="showNewUserModel"
      hide-header
      hide-footer
      size="lg"
      no-close-on-backdrop
    >
      <NewUserModal
        @closeModal="closeNewUserModal"
        @createNewUserModal="createNewUserModal"
        @reloadUserInfo="reloadUserInfo"
      ></NewUserModal>
    </b-modal>
  </div>
</template>

<script>
import setupScrollableDiv from "@/Extend/makeDivScrollable";
import onResize from "@/Extend/onResize";
import UserManagement from "../../Extend/UserManagement";
import UserList from "./components/UserList.vue";
import UserDetails from "./components/UserDetails.vue";
import RowCollapse from "../../components/RowCollapse.vue";
import NewUserModal from "./components/NewUserModal.vue";
import UsersNotFound from "../../components/notFound.vue";

export default {
  name: "UserManagement",
  components: {
    UserList,
    UserDetails,
    RowCollapse,
    NewUserModal,
    UsersNotFound,
  },
  mixins: [setupScrollableDiv, onResize, UserManagement],
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/components/Modal.scss";

.user-management {
  @include splitted-view-styles;
  padding-top: 4%;
  padding-bottom: 20px;
  &__title {
    color: #043663;
    font-weight: bold;
    position: absolute;
    right: 33px;
    top: 105px;
    font-size: 1.2rem;
  }
  &__button {
    @include button-back;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 1rem;
    &--new-user {
      @include primary-button;
      display: flex;
      width: 150px;
      text-align: center;
      margin: 1rem;
      padding: 9px;
      span {
        width: 100%;
      }
      @media (max-width: 800px) {
        width: 100%;
        margin: auto;
      }
    }
  }
  &__left {
    position: relative;
    padding-left: 0px;
    min-height: 700px;
    border-right: 1px solid $color-border-container;
    padding-right: 1rem;
    height: 80vh;
  }
  &__right {
    padding-right: 4px;
  }
}
.user-management--mobile {
  position: relative;
  &__notFound {
    position: absolute;
    top: 0;
    left: 40%;
  }
}
.noUsers-found {
  margin: 0 auto;
}

.loading {
  @include loading-overlay;
  justify-content: flex-end;
  svg,
  p {
    color: $color-primary-company;
  }
}
::v-deep .table-rows-container {
  overflow-y: auto;
}

::v-deep .loading-overlay {
  .position-absolute {
    width: 100%;
  }
  .b-overlay {
    inset: 0% !important;
  }
}
</style>
